/*
 * @Author: 冯杰
 * @Date: 2021-10-14 16:14:56
 * @LastEditTime: 2021-10-19 18:46:28
 * @FileName: 经销商服务合同列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'DealerContractList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/contractSign/findByConditions',
      requestType: 'GET',
      params: {
        userType: '1',
      },
    };
  },
  components: {
    Modal,
  },
  methods: {},
  created() {
    // 重写获取配置信息
    this.getConfigList('dealer_contract_list');
  },
};
